<app-spinner />
<ngx-loading-bar [includeSpinner]="false" />
<app-header *ngIf="isShowingHeaderAndSidebar$ | async" />
<main class="masterContainer">
  <app-sidebar *ngIf="isShowingHeaderAndSidebar$ | async" class="app-sidebar" />
  <section class="app-content">
    <router-outlet />
  </section>
</main>
<app-footer />
