// eslint-disable-next-line @typescript-eslint/no-explicit-any
const STRING: Record<string, any> = {}

STRING['TITLE'] = '모두의주차장'

STRING['Day'] = {
  monday: '월요일',
  tuesday: '화요일',
  wednesday: '수요일',
  thursday: '목요일',
  friday: '금요일',
  saturday: '토요일',
  sunday: '일요일',
  weekday: '평일',
  weekend: '주말',
  holiday: '공휴일'
}

STRING['List'] = {
  Number: '이벤트 번호',
  ParkSeq: '주차장 번호',
  ParkName: '주차장명',
  Status: '상태',
  RegeDt: '등록일',
  Action: {
    Name: '기능',
    Read: '조회',
    Update: '수정',
    Delete: '삭제'
  },
  From: '부터',
  To: '까지',
  Activate: '활성화',
  UnActivate: '비활성화',
  SoldIn: '판매중',
  SoldOut: '판매중지'
}
STRING['SubTitle'] = {
  Total: '총',
  Numberic: '개의'
}

STRING['Write'] = '작성'
STRING['Close'] = '닫기'

STRING['TicketLst'] = {
  EventName: '주차장',
  EventAndCoupon: '선불주차권',
  TargetPark: {
    DailyTotalSet: '오늘 총 발급량',
    Set: '누적',
    Daily: '현재 이용 중',
    DailyMax: '최대 이용 가능 수량',
    Get: '사용량',
    Ttl: '총량',
    couponStatusValue: '판매금액',
    fixedPrice: '정산금액'
  },
  UserName: '쿠폰배정자',
  CouponName: '주차권명',
  CouponCode: '쿠폰코드',
  NoTargetCoupon: '미배정쿠폰',
  EventDue: '운영기간',
  Action: {
    Use: '사용현황',
    Serial: '일련번호보기'
  },
  EventTme: '사용시간',
  CustomerInfo: '구매자정보',
  UseYN: '사용여부',
  ActiveDate: '발행일자',
  TicketType: '주차권처리타입',
  ReserveResale: '매진처리등록',
  UpdateReserve: '매진처리수정',
  ReserveSoldOutSchedule: '매진처리 예약등록',
  UpdateSoldOutSchedule: '매진처리 예약수정',
  TicketDelete: '삭제',
  interlockInfo: {
    ticketInfo: [
      {
        name: 'Seq',
        key: 'couponSeq'
      },
      {
        name: '이름',
        key: 'couponName'
      },
      {
        name: '정산가',
        key: 'fixedPrice'
      },
      {
        name: '판매가',
        key: 'couponStatusValue'
      }
    ],
    parkinglotInfo: [
      {
        name: '이름',
        key: 'parkingLotNameExposure'
      },
      {
        name: 'Seq',
        key: 'parkingLotSeq'
      }
    ],
    eventInfo: [
      {
        name: '이름',
        key: 'eventName'
      },
      {
        name: 'Seq',
        key: 'eventSeq'
      }
    ],
    title: {
      ticket: '주차권 정보',
      ticketType: '주차권 처리타입',
      event: '이벤트 정보',
      parkinglot: '주차장 정보',
      interlock: '연동정보'
    }
  }
}

STRING['CouponStatus'] = ['사용안함', '사용완료', '결제취소', '미결제', '반납', '만료']
STRING['CouponDefaultThumbnail'] = 'https://image.modu.cloud/coupon/default/thumbnail.png'
STRING['ParkFindKey'] = [
  { value: 'parkingLotSeq', name: '주차장번호' },
  { value: 'name', name: '주차장이름' },
  { value: 'phone', name: '연락처' },
  { value: 'address', name: '주소' }
]
// 차량번호, 이용자 이름 , 이용자 연락처, 주차장명으로 검색할 수 있다.
STRING['MonthlyParkFindKey'] = [
  { value: 0, name: '통합검색' },
  { value: 1, name: '차량번호' },
  { value: 2, name: '이용자 이름' },
  { value: 3, name: '이용자 연락처' }
]
STRING['MonthlyParkPeriodKey'] = [
  { value: 1, name: '이용시작일' },
  { value: 2, name: '이용종료일' },
  { value: 3, name: '결제일' }
]
STRING['EVFindKey'] = [
  { value: 'parkingLotSeq', name: '충전소번호' },
  { value: 'name', name: '충전소이름' },
  { value: 'phone', name: '연락처' },
  { value: 'address', name: '주소' }
]

STRING['PaymentFindKey'] = {
  moduAdmin: [
    { key: null, word: '통합 검색' },
    { key: 'phone', word: '결제자 연락처' },
    { key: 'carNum', word: '결제자 차량번호' },
    { key: 'userName', word: '결제자 이름/닉네임' },
    { key: 'parkinglotSeq', word: '주차장 고유번호' },
    { key: 'parkinglotName', word: '주차장 이름' },
    { key: 'shareParkinglotNum', word: '거주자 공유 구획번호' },
    { key: 'shareParkinglotName', word: '일반 공유 이름' },
    { key: 'shareSeq', word: '거주자/일반 공유 고유번호' }
  ],
  // 파트너
  partner: [
    { key: 'carNum', word: '차량번호' },
    { key: 'parkinglotName', word: '주차장 이름' }
  ],
  // 지자체
  district: [
    { key: 'carNum', word: '차량번호' },
    { key: 'shareParkinglotNum', word: '공유 구획번호' }
  ]
}
STRING['Error'] = {
  NoCoupon: '쿠폰을 발급하지 않았거나 주차장을 지정하지 않은 이벤트입니다.',
  image: {
    NoFile: '파일이 선택되지 않았습니다. 사진 파일을 선택해 주세요.',
    InvalidSize: '크기가 100 * 100 이상의 사진만 가능합니다.',
    S3Fail: '파일 업로드에 실패하였습니다. 모두의 주차장에 문의바랍니다',
    Delay: "이미지 파일이 보이지 않을 시 '이미지새로고침' 버튼을 눌러주세요"
  }
}

STRING['couponNotice'] = {
  noticeBasic: {
    daily:
      '- 만차 혹은 현장 사정에 따라 주차가 어려울 수 있음\n' +
      '- 선불주차권으로 입차한 상태에서 구매 시 사용 불가\n' +
      '- 입출차는 1회만 가능하며, 주차권으로 출차 후 재입차 시 현장요금 적용\n' +
      '- 유효시간 외 주차 시 주차권 적용 취소 및 총 이용시간에 대해 전액 현장요금 적용\n' +
      '- 주차권 2개 이상 연속 사용 및 연박 주차 시 총 이용시간에 대해 전액 현장요금 적용\n' +
      '  ex) 당일권+당일권, 당일권+심야권, 3시간권+5시간권 불가\n' +
      '- 주차권 구매 후 입차 시 주차권 환불 불가\n' +
      '- 구매 당일 동일 주차권 동일 차량번호로 재구매 불가\n' +
      '- 주차장에서의 도난, 분실, 사고는 일체 책임지지 않음',
    monthly:
      '- 환불 시 환불정책에 따라 환불금액이 없을 수 있음\n' +
      '- 환불정책은 어플 내 서비스 이용안내 또는 구매 전 주차장 공통 유의사항에서 확인 가능\n' +
      '- 주차장에서의 도난, 분실, 사고는 일체 책임지지 않음'
  },
  smsMsg:
    '[모두의주차장 주차권 유의사항]\n' +
    '- 유효시간 : \n' +
    '- 입출차 방법 : 번호판 자동 인식\n' +
    '- 차단기 열리지 않을 시 차단기 호출버튼 눌러서 문의\n' +
    '- 입차 후 주차권 결제시 사용불가\n' +
    '- 사전정산기 사용시 주차권 사용불가',
  noticePrePurchase: {
    daily:
      '- 이용시간, 요일, 방식 등 주차장 특이사항 ex) 리모컨 수령/관리사무소 방문 필수/주말 이용 불가, 주말은 자주식 이용 가능\n' +
      '\n' +
      '[기계식 입차제원]\n' +
      '-  ~ 이상 입차 불가 or ~ 이하까지 입차 가능\n' +
      '- 전장 mm 전폭 mm 전고 mm 중량 kg',
    monthly:
      '- 이용시간, 요일, 방식 등 주차장 특이사항 ex) 리모컨 수령/관리사무소 방문 필수/주말 이용 불가, 주말은 자주식 이용 가능\n' +
      '\n' +
      '[기계식 입차제원]\n' +
      '-  ~ 이상 입차 불가 or ~ 이하까지 입차 가능\n' +
      '- 전장 mm 전폭 mm 전고 mm 중량 kg'
  },
  noticePostPurchase: {
    daily:
      '- 출차 방법: 번호판 자동 인식/차단기 호출버튼/고객센터(080-330-3600)로 출차요청/현장관리자 주차권 확인\n' +
      '- 차단기 열리지 않을 시 호출버튼 눌러서 문의\n' +
      '- 모두의주차장 주차권 외 할인 적용 불가\n' +
      '- 사전정산기 사용 시 주차권 적용 불가',
    monthly:
      '- 당일 등록 불가,\n' +
      '- 출차 방법: 번호판 자동 인식/차단기 호출버튼/고객센터(080-330-3600)로 출차요청/현장관리자 주차권 확인\n' +
      '- 차단기 열리지 않을 시 호출버튼 눌러서 문의\n' +
      '- 발송된 신청서를 작성하지 않은 경우 등록 불가하며, 발생한 현장요금은 환불 불가'
  }
}

STRING['parkInfo'] = {
  기본정보: {
    parkingLotSeq: {
      name: '고유번호',
      type: 'text'
    },
    companySeq: {
      name: '협력업체'
    },
    parkingLotName: {
      name: '주차장 이름',
      type: 'text'
    },
    parkingLotNameExposure: {
      name: '주차장 노출 이름'
    },
    parkingLotAddr: {
      name: '주소 (시, 도 단위)'
    },
    parkingLotAddr1: {
      name: '주소 (구 단위)'
    },
    parkingLotAddr2: {
      name: '주소 ( 단위)'
    },
    parkingLotAddr3: {
      name: '주소 (나머지)'
    },
    parkingLotNewAddr: {
      name: '도로명 주소'
    },
    parkingLotOldAddr: {
      name: '지번주소'
    },
    parkingLotZipCode: {
      name: '우편번호',
      type: 'text'
    },
    parkingLotTel: {
      name: '주차장 유선 번호',
      type: 'text'
    },
    parkingLotPhone: {
      name: '주차장 무선 번호',
      type: 'text'
    },
    parkingLotLatitude: {
      name: '위도'
    },
    parkingLotLongitude: {
      name: '경도'
    },
    parkingLotDesc: {
      name: '주차장설명',
      type: 'text'
    },
    categorySeq: {
      name: '카테고리 구분',
      type: 'text'
    },
    typeSeq: {
      name: '타입 구분',
      type: 'text'
    },
    operationSeq: {
      name: '운영방식',
      type: 'text'
    },
    sourceInfo: {
      name: '출처정보',
      type: 'text'
    },
    moduComment: {
      name: '한줄안내',
      type: 'text'
    }
  },
  시간정보: {
    weekdayBeginTime: {
      name: '평일운영시작',
      type: 'text'
    },
    weekdayEndTime: {
      name: '평일운영종료',
      type: 'text'
    },
    saturdayBeginTime: {
      name: '토요일운영시작',
      type: 'text'
    },
    saturdayEndTime: {
      name: '토요일운영종료',
      type: 'text'
    },
    sundayBeginTime: {
      name: '일요일운영시작',
      type: 'text'
    },
    sundayEndTime: {
      name: '일요일운영종료',
      type: 'text'
    },
    holidayBeginTime: {
      name: '공휴일운영시작',
      type: 'text'
    },
    holidayEndTime: {
      name: '공휴일운영종료',
      type: 'text'
    },
    weekdayOpenBeginTime: {
      name: '평일개방시작',
      type: 'text'
    },
    weekdayOpenEndTime: {
      name: '평일개방종료',
      type: 'text'
    },
    saturdayOpenBeginTime: {
      name: '토요일개방시작',
      type: 'text'
    },
    saturdayOpenEndTime: {
      name: '토요일개방종료',
      type: 'text'
    },
    sundayOpenBeginTime: {
      name: '일요일개방시작',
      type: 'text'
    },
    sundayOpenEndTime: {
      name: '일요일개방종료',
      type: 'text'
    },
    holidayOpenBeginTime: {
      name: '공휴일개방시작',
      type: 'text'
    },
    holidayOpenEndTime: {
      name: '공휴일개방종료',
      type: 'text'
    },
    operationTimeDesc: {
      name: '기타시간정보',
      type: 'text'
    }
  },
  기타정보: {
    capacity: {
      name: '주차면수',
      type: 'number'
    },
    isAttached: {
      name: '부설주차장',
      type: 'checkbox'
    },
    isFree: {
      name: '무료',
      type: 'checkbox'
    },
    isCard: {
      name: '카드결제',
      type: 'checkbox'
    },
    isCardPartner: {
      name: '카드사제휴',
      type: 'checkbox'
    },
    isDisabled: {
      name: '장애인주차면',
      type: 'checkbox'
    },
    isMechanical: {
      name: '기계식주차장',
      type: 'checkbox'
    },
    isWoman: {
      name: '여성전용주차장',
      type: 'checkbox'
    },
    isTransfer: {
      name: '환승주차장',
      type: 'checkbox'
    },
    groundFloors: {
      name: '지상층수',
      type: 'number'
    },
    basementFloors: {
      name: '지하층수',
      type: 'number'
    },
    isOutsourcing: {
      name: '위탁운영',
      type: 'checkbox'
    },
    isReview: {
      name: '재검토대상',
      type: 'checkbox'
    },
    isScannerNumberPlate: {
      name: '번호판인식',
      type: 'checkbox'
    },
    isManless: {
      name: '무인운영',
      type: 'checkbox'
    }
  },
  사진정보: {
    pricePhoto: {
      name: '요금표사진',
      type: 'img'
    },
    innerPhoto: {
      name: '내부사진',
      type: 'img'
    },
    ticketPhoto: {
      name: '주차권사진',
      type: 'img'
    },
    floorPlanPhoto: {
      name: '평면도사진',
      type: 'img'
    },
    photo1: {
      name: '사진1',
      type: 'img'
    },
    photo2: {
      name: '사진2',
      type: 'img'
    },
    photo3: {
      name: '사진3',
      type: 'img'
    },
    photo4: {
      name: '사진4',
      type: 'img'
    }
  },
  '가격 정보': {
    priceInfo: {
      name: '가격 설정',
      type: 'modal'
    }
  },
  '할인 정보': {
    discountInfo: {
      name: '할인 설정',
      type: 'modal'
    }
  }
}

STRING['blockInfo'] = {
  price: {
    columns: {
      priceName: {
        name: '가격이름',
        valueType: 'text'
      },
      operCode: {
        name: '대상유형',
        valueType: 'text'
      },
      operCodeEtc: {
        name: '기타대상유형',
        valueType: 'text'
      },
      positionCode: {
        name: '층수',
        valueType: 'text'
      },
      dayCode: {
        name: '요일',
        valueType: 'text'
      },
      timeCode: {
        name: '시간',
        valueType: 'text'
      },
      initFreeTime: {
        name: '초기무료',
        valueType: 'text'
      },
      initTime: {
        name: '기본시간',
        valueType: 'text'
      },
      initPrice: {
        name: '기본요금',
        valueType: 'text'
      },
      basicTime: {
        name: '추가시간',
        valueType: 'text'
      },
      basicPrice: {
        name: '추가요금',
        valueType: 'text'
      },
      afterExtraTime: {
        name: '할증단위',
        valueType: 'text'
      },
      extraTime: {
        name: '할증시간',
        valueType: 'text'
      },
      extraPrice: {
        name: '할증요금',
        valueType: 'text'
      },
      startTime: {
        name: '시작시간',
        valueType: 'text'
      },
      endTime: {
        name: '종료시간',
        valueType: 'text'
      },
      priceDetail: {
        name: '기타가격설명',
        valueType: 'text'
      },
      prepayment: {
        name: '선불/후불',
        valueType: 'text'
      },
      fixedPrice: {
        name: '요금',
        valueType: 'text'
      }
    },
    info: [
      null,
      [
        'initFreeTime',
        'initTime',
        'initPrice',
        'basicTime',
        'basicPrice',
        'afterExtraTime',
        'extraTime',
        'extraPrice',
        'startTime',
        'endTime'
      ],
      [
        'operCode',
        'operCodeEtc',
        'positionCode',
        'dayCode',
        'timeCode',
        'fixedPrice',
        'prepayment',
        'priceDetail',
        'startTime',
        'endTime'
      ],
      [
        'operCode',
        'operCodeEtc',
        'positionCode',
        'dayCode',
        'timeCode',
        'fixedPrice',
        'prepayment',
        'priceDetail',
        'startTime',
        'endTime'
      ],
      [
        'operCode',
        'operCodeEtc',
        'positionCode',
        'dayCode',
        'timeCode',
        'fixedPrice',
        'prepayment',
        'priceDetail',
        'startTime',
        'endTime'
      ],
      [
        'operCode',
        'operCodeEtc',
        'positionCode',
        'dayCode',
        'timeCode',
        'fixedPrice',
        'prepayment',
        'priceDetail',
        'startTime',
        'endTime'
      ],
      [
        'operCode',
        'operCodeEtc',
        'positionCode',
        'dayCode',
        'timeCode',
        'fixedPrice',
        'prepayment',
        'priceDetail',
        'startTime',
        'endTime'
      ],
      ['priceDetail', 'startTime', 'endTime'],
      [],
      [
        'priceName',
        'initFreeTime',
        'initTime',
        'initPrice',
        'basicTime',
        'basicPrice',
        'afterExtraTime',
        'extraTime',
        'extraPrice',
        'startTime',
        'endTime',
        'priceDetail'
      ],
      [
        'priceName',
        'initFreeTime',
        'initTime',
        'initPrice',
        'basicTime',
        'basicPrice',
        'afterExtraTime',
        'extraTime',
        'extraPrice',
        'startTime',
        'endTime'
      ]
    ],
    name: [
      null,
      ['시간 요금', 'time'],
      ['할인 주차권', 'discount'],
      ['일 주차', 'day'],
      ['주 주차', 'week'],
      ['월 주차', 'month'],
      ['얼리버드', 'earlyBird'],
      ['기타설명', 'etc'],
      null,
      ['할증요금', 'extra'],
      ['플렉서블', 'flexible']
    ]
  },
  discount: {
    columns: {
      discountName: {
        name: '할인이름',
        valueType: 'text'
      },
      priceDiscount: {
        name: '할인액',
        valueType: 'text'
      },
      timeDiscount: {
        name: '할인시간',
        valueType: 'text'
      },
      rateDiscount: {
        name: '할인율',
        valueType: 'text'
      },
      conditionCode: {
        name: '할인조건',
        valueType: 'text'
      },
      etcCondition: {
        name: '기타조건',
        valueType: 'text'
      },
      useCondition: {
        name: '최소금액조건',
        valueType: 'text'
      },
      discountDetail: {
        name: '할인상세',
        valueType: 'text'
      }
    },
    info: {
      1: ['useCondition', 'timeDiscount', 'conditionCode', 'etcCondition', 'discountDetail'],
      2: ['rateDiscount', 'conditionCode', 'etcCondition', 'discountDetail'],
      3: ['priceDiscount', 'conditionCode', 'etcCondition', 'discountDetail'],
      4: ['conditionCode', 'etcCondition', 'discountDetail'],
      5: ['timeDiscount', 'rateDiscount', 'conditionCode', 'etcCondition', 'discountDetail'],
      6: ['discountDetail'],
      7: ['timeDiscount', 'etcCondition', 'discountDetail']
    },
    name: {
      1: ['시간 할인', 'time'],
      2: ['% 할인', 'percent'],
      3: ['금액 할인', 'price'],
      4: ['무료', 'free'],
      5: ['혼합할인', 'complex'],
      6: ['기타할인정보', 'etc'],
      7: ['허용구간', 'phase']
    }
  }
}

STRING['MmainNotice'] = {
  promotionTypes: ['프로모션', '파격할인', '매진임박']
}

export default STRING
