import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { StorageService } from '@/app/core/services/storage.service'

import type { ActivatedRouteSnapshot, CanActivate, GuardResult, MaybeAsync, RouterStateSnapshot } from '@angular/router'
import type { AdminUserInfo } from '@/types/api/auth-api.type'

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private router: Router
  ) {}

  private isLoggedIn(): boolean {
    return this.storageService.getItem('isLoggedIn') ?? false
  }
  private loginInfo(): AdminUserInfo | null {
    return this.storageService.getItem('loginInfo')
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    if (!this.isLoggedIn() && !this.loginInfo()) {
      if (state.url === '/login') {
        return true
      }
      alert('로그인이 필요합니다.')
      this.router.navigate(['login'], { replaceUrl: true })
      return false
    }
    return true
  }
}
