import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import type { OnDestroy } from '@angular/core'

@Directive({
  selector: '[window-width]',
  exportAs: 'windowWidth',
  standalone: true
})
export class WindowWidthDirective implements OnDestroy {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.updateWidth(window.innerWidth)
  }

  private widthSubject = new BehaviorSubject<number>(window.innerWidth) // 초기값
  width$ = this.widthSubject.asObservable() // 외부에서 구독 가능

  @HostListener('window:resize', ['$event'])
  onResize(_event: Event) {
    const newWidth = window.innerWidth
    if (this.widthSubject.value !== newWidth) {
      this.updateWidth(newWidth)
    }
  }

  private updateWidth(newWidth: number) {
    this.widthSubject.next(newWidth) // 상태 업데이트
    this.renderer.setProperty(this.el.nativeElement, 'date-width', String(newWidth)) // DOM 업데이트
  }

  ngOnDestroy(): void {
    this.widthSubject.complete()
  }
}
