<div id="sidebar-wrapper">
  <ul *ngFor="let menu of menus()" class="sidebar-nav">
    <!-- Level 1 -->
    <li *ngIf="menu.isSubMenu === 0" class="sidebar-brand">
      <a (click)="navigateMenu(menu)">
        {{ menu.menuName }}
      </a>
    </li>

    <li
      *ngIf="menu.isSubMenu === 1"
      [class]="['sidebar-brand', 'gp' + menu.menuCode, menu.isExpanded ? 'active' : '']"
      (click)="toggleMenu(menu)"
      role="button"
      [attr.aria-expanded]="menu.isExpanded"
    >
      <a class="parent-menu-name">{{ menu.menuName }} <b class="caret"></b></a>
    </li>

    <!-- Level 2 (SubMenu) -->
    <li *ngIf="menu.subMenu" [@collapseAnimation]="menu.isExpanded ? 'expanded' : 'collapsed'" class="sidebar-brand">
      <ul class="sidebar-nav">
        <ng-container *ngFor="let sub of menu.subMenu">
          <li *ngIf="sub.isSubMenu === 0 && openMenuCodesForAdmin.includes(sub.menuCode)">
            <a (click)="navigateMenu(sub)" [style.paddingLeft.px]="20">
              {{ sub.menuName }}
            </a>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>
</div>
