import { CommonModule } from '@angular/common'
import { Component, computed } from '@angular/core'
import { SpinnerService } from '@/app/core/services/spinner.service'

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
  standalone: true,
  imports: [CommonModule]
})
export class SpinnerComponent {
  isLoading = computed(() => this.spinnerService.loading$)

  constructor(private spinnerService: SpinnerService) {}
}
