import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { StorageService } from '@/app/core/services/storage.service'
import { AdminPermissionService } from '@/app/core/services/admin-permission.service'

import type { HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http'

let isErrorHandled = false

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const router = inject(Router)
  const storage = inject(StorageService)
  const authUser = inject(AdminPermissionService)

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const isModuAdmin = authUser.isModuAdmin()
      if (!isErrorHandled) {
        isErrorHandled = true

        if (error.status === 500 && error.error.message === '권한이 없습니다.') {
          alert(error.error.message)
          if (isModuAdmin) router.navigate(['dashboard'])
          else router.navigate(['partner'])
        }

        if (error.status === 401) {
          alert(error.error.message)
          storage.setItem('isLoggedIn', false)
          storage.removeItem('loginInfo')
          router.navigate(['login'])
        }

        setTimeout(() => {
          isErrorHandled = false
        }, 1000)
      }

      return throwError(() => error)
    })
  )
}
