import { inject } from '@angular/core'
import { HttpRequest } from '@angular/common/http'
import { LoadingBarService } from '@ngx-loading-bar/core'
import { finalize } from 'rxjs'

import { SpinnerService } from '@/app/core/services/spinner.service'

import type { HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http'

export const withCredentialsInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const spinnerService = inject(SpinnerService)
  const loadingBarService = inject(LoadingBarService)

  const loadingBar = loadingBarService.useRef()

  loadingBar.start()
  spinnerService.show()

  const clonedReq = req.clone({ withCredentials: true })
  return next(clonedReq).pipe(
    finalize(() => {
      spinnerService.hide()
      loadingBar.complete()
    })
  )
}
