import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import type { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storageSubject = new BehaviorSubject<unknown | null>(null)

  setItem<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem<T = any>(key: string): T | null {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : null
  }

  removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  clear(): void {
    localStorage.clear()
  }

  hasItem(key: string): boolean {
    return localStorage.getItem(key) !== null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStorageItemObservable<T = any>(key: string): Observable<T> {
    const item = this.getItem<T>(key)
    if (item) {
      this.storageSubject.next(item)
    }
    return this.storageSubject.asObservable() as Observable<T>
  }
}
