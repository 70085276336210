import { importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http'
import { provideRouter, withHashLocation } from '@angular/router'
import { routes } from '@/app/app.routes'
import localKo from '@angular/common/locales/ko'
import { registerLocaleData } from '@angular/common'
import { withCredentialsInterceptor } from '@/app/core/interceptors/with-credentials.interceptor'
import { authInterceptor } from '@/app/core/interceptors/auth.interceptor'

import type { ApplicationConfig } from '@angular/core'

registerLocaleData(localKo)

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withFetch(), withInterceptors([withCredentialsInterceptor, authInterceptor])),
    importProvidersFrom(BrowserAnimationsModule),
    [{ provide: LOCALE_ID, useValue: 'ko-KR' }]
  ]
}
