import { Injectable, signal, computed, effect } from '@angular/core'
import { anyPass, equals, has, lt, pipe, prop, propEq } from 'ramda'
import { StorageService } from '@/app/core/services/storage.service'
import type { AdminUserInfo } from '@/types/api/auth-api.type'

@Injectable({
  providedIn: 'root'
})
export class AdminPermissionService {
  constructor(private storageService: StorageService) {
    this.loadUserInfo()
    this.loadIsLoggedIn()

    effect(
      () => {
        this.isAuthenticated.set(this.userInfo() !== null)
      },
      { allowSignalWrites: true }
    )
  }

  readonly userInfo = signal<AdminUserInfo | null>(null)
  readonly isLoggedIn = signal<boolean>(false)
  readonly isAuthenticated = signal<boolean>(this.userInfo() !== null)

  // roleLevel을 computed로 계산
  readonly roleLevel = computed(() => this.userInfo()?.roleLevel || 0)

  computeWithRoleLevel = (compute: (val: number) => boolean) => pipe(prop('roleLevel'), compute)

  // 역할에 따른 Boolean 값 계산 로직
  readonly isModuAdmin = computed(() => {
    const userInfo = this.userInfo()
    if (!userInfo) return false
    return this.computeWithRoleLevel(lt(50))(userInfo)
  })

  readonly isSupervisor = computed(() => {
    const userInfo = this.userInfo()
    if (!userInfo) return false
    return this.computeWithRoleLevel(equals(45))(userInfo)
  })

  readonly isManager = computed(() => {
    const userInfo = this.userInfo()
    if (!userInfo) return false
    return this.computeWithRoleLevel(equals(35))(userInfo)
  })

  readonly isDiscounter = computed(() => {
    const userInfo = this.userInfo()
    if (!userInfo) return false
    return this.computeWithRoleLevel(equals(20))(userInfo)
  })

  readonly isPartnerMaster = computed(() => {
    const userInfo = this.userInfo()
    if (!userInfo) return false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return propEq('roleName', '제휴마스터')(userInfo as any)
  })

  readonly isPartnerAdmin = computed(() => {
    const userInfo = this.userInfo()
    if (!userInfo) return false
    return anyPass([this.isSupervisor, this.isManager, this.isDiscounter, this.isPartnerMaster])()
  })

  readonly isGuAdmin = computed(() => {
    const userInfo = this.userInfo()
    if (!userInfo) return false
    return anyPass([this.isModuAdmin, has('guSeq')])(userInfo)
  })

  // userInfo 초기화
  private loadUserInfo() {
    this.storageService.getStorageItemObservable<AdminUserInfo>('loginInfo').subscribe((userInfo) => {
      this.userInfo.set(userInfo)
    })
  }
  private loadIsLoggedIn() {
    this.storageService.getStorageItemObservable<boolean>('isLoggedIn').subscribe((isLoggedIn) => {
      this.isLoggedIn.set(isLoggedIn)
    })
  }
}
