import { bootstrapApplication } from '@angular/platform-browser'
import moment from 'moment'
import { appConfig } from '@/app/app.config'
import { AppComponent } from '@/app/app.component'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.ko.min'
import 'moment/locale/ko'

moment.locale('ko')

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err))
