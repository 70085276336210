export const environment = {
  production: true,
  appEnv: 'prod',
  environment: 'production',
  mode: 'prod',
  port: '80',
  newAdminURL: 'https://admin.modu.cloud/',
  daumKey: '48105f5eceef0ea117bf360a5cf15226',
  naverKey: 'jccceilvyn',
  apiServer: 'https://admin-app.moduparking.com/',
  tmapApi: 'https://api2.sktelecom.com/tmap/pois',
  tmapKey: 'a2a0106c-ff7a-4ed3-a1b3-060f7cad9532',
  storageUrl: 'https://page.modu.kr/static/parky/',
  objectStorageUrl: 'https://image.modu.cloud/'
}