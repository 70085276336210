<header class="navbar navbar-default navbar-fixed-top moduHeader" role="navigation" window-width #width="windowWidth">
  <div class="navbar-header">
    <a href="#/" class="navbar-brand">{{ STRING['TITLE'] }}</a>
    <span *ngIf="APP_ENV !== 'prod'" class="navbar-brand env-title">{{ APP_ENV }}</span>
    <button
      type="button"
      class="navbar-toggle collapsed"
      data-toggle="collapse"
      data-target="#mainMenuList"
      aria-expanded="false"
      (click)="toggleMobileDropdown()"
    >
      <span class="sr-only">Toggle navigation</span>
      <!-- if mobile -->
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>

  <div
    [@collapseAnimation]="
      ((width.width$ | async) ?? 0) < 768 ? (mobileDropdownToggle ? 'expanded' : 'collapsed') : null
    "
    class="collapse navbar-collapse"
    [class.in]="((width.width$ | async) ?? 0) < 768 && mobileDropdownToggleDelay"
    id="mainMenuList"
  >
    <ul class="nav navbar-nav">
      <li *ngFor="let menu of menus()" [class.dropdown]="menu.isSubMenu === 1" [class.open]="menu.isExpanded">
        <a *ngIf="menu.isSubMenu === 0" class="mobileMenu" (click)="redirect(menu.uri || '')">{{ menu.menuName }}</a>
        <a
          *ngIf="menu.isSubMenu === 1"
          class="dropdown-toggle"
          data-toggle="dropdown"
          (click)="menu.isExpanded = !menu.isExpanded"
          >{{ menu.menuName }} <b class="caret"></b
        ></a>

        <ul *ngIf="menu.isSubMenu === 1" class="dropdown-menu">
          <li
            *ngFor="let sub of menu.subMenu"
            [class]="sub.isSubMenu === 1 ? ['dropdown', 'dropdown-submenu'] : ''"
            (click)="sub.isExpanded = !sub.isExpanded"
          >
            <a
              *ngIf="sub.isSubMenu === 0 && openMenuCodesForAdmin.includes(sub.menuCode)"
              class="mobileMenu"
              (click)="redirect(sub.uri || '')"
              >{{ sub.menuName }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="nav navbar-nav navbar-right">
      <li *ngIf="userInfo" [class.open]="dropdownToggle">
        <a *ngIf="userInfo.email" class="dropdown-toggle" data-toggle="dropdown" (click)="toggleDropdown()"
          >{{ userInfo.email }}님, 환영합니다. <b class="caret"></b>&nbsp;&nbsp;</a
        >
        <ul class="dropdown-menu">
          <li (click)="logout()"><a>로그아웃</a></li>
          <li (click)="redirect('admin/' + userInfo.adminSeq)" *ngIf="isModuAdmin()">
            <a>계정설정</a>
          </li>
          <li (click)="redirect('admin/password')" *ngIf="isModuAdmin() || !isPartnerAdmin()">
            <a>비밀번호 변경</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</header>
