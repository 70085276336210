import type { Routes, Route } from '@angular/router'
import { RouteGuard } from '@/app/core/guard/route.guard'

const route: Route[] = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [RouteGuard],
    loadComponent: () => import('@/app/login/login.component').then((C) => C.LoginComponent)
  },
  {
    path: 'dashboard',
    canActivate: [RouteGuard],
    loadComponent: () => import('@/app/main/dashboard/dashboard.component').then((C) => C.DashboardComponent)
  },
  // {
  //   path: 'statistics',
  //   canActivate: [RouteGuard],
  //   loadComponent: () => import('@/app/main/statistics/statistics.component').then((C) => C.StatisticsComponent)
  // },
  // {
  //   path: 'payment',
  //   children: [
  //     { path: '', redirectTo: '/statistics', pathMatch: 'full' },
  //     { path: 'public', redirectTo: '/statistics', pathMatch: 'full' },
  //     {
  //       path: ':paymentSeq',
  //       canActivate: [RouteGuard],
  //       loadComponent: () => import('@/app/payment/payment-detail.component').then((C) => C.PaymentDetailComponent)
  //     }
  //   ]
  // },

  // {
  //   path: 'holiday/list',
  //   canActivate: [RouteGuard],
  //   loadComponent: () => import('@/app/service/holiday/holiday.component').then((C) => C.HolidayComponent)
  // },
  // {
  //   path: 'ticket/holiday',
  //   canActivate: [RouteGuard],
  //   loadComponent: () =>
  //     import('@/app/service/holiday-reservation/holiday-reservation.component').then(
  //       (C) => C.HolidayReservationComponent
  //     )
  // },
  {
    path: 'field',
    children: [
      { path: '', redirectTo: 'parking', pathMatch: 'full' },
      {
        path: 'parking',
        canActivate: [RouteGuard],
        loadComponent: () => import('@/app/partner/parking-list.component').then((C) => C.ParkingListComponent)
      },
      {
        path: 'refund',
        canActivate: [RouteGuard],
        loadComponent: () =>
          import('@/app/partner/refund-management.component').then((C) => C.RefundManagementComponent)
      }
    ]
  },
  {
    path: 'partner',
    canActivate: [RouteGuard],
    loadComponent: () => import('@/app/partner/payment-management.component').then((C) => C.PaymentManagementComponent)
  },

  {
    path: 'iot',
    children: [
      { path: '', redirectTo: 'statistic', pathMatch: 'full' },
      {
        path: 'statistic',
        canActivate: [RouteGuard],
        loadComponent: () => import('@/app/borough/iot/iot-statistic.component').then((C) => C.IotStatisticComponent)
      },
      {
        path: 'list',
        canActivate: [RouteGuard],
        loadComponent: () => import('@/app/borough/iot/iot-list.component').then((C) => C.IotListComponent)
      },
      {
        path: 'signal/:iotCode',
        canActivate: [RouteGuard],
        loadComponent: () => import('@/app/borough/iot/iot-detail.component').then((C) => C.IotDetailComponent)
      }
    ]
  },

  {
    path: 'sharedparkinglots',
    children: [
      {
        path: '',
        canActivate: [RouteGuard],
        loadComponent: () =>
          import('@/app/borough/sharedparkinglots/sharedparkinglot-list.component').then(
            (C) => C.SharedParkinglotListComponent
          )
      },
      {
        path: ':shareSeq',
        canActivate: [RouteGuard],
        loadComponent: () =>
          import('@/app/borough/sharedparkinglots/sharedparkinglot-detail.component').then(
            (C) => C.SharedParkinglotDetailComponent
          )
      }
    ]
  },
  {
    path: 'sharedparkinglot/time/:shareSeq',
    canActivate: [RouteGuard],
    loadComponent: () =>
      import('@/app/borough/sharedparkinglots/sharedparkinglot-time.component').then(
        (C) => C.SharedParkinglotTimeComponent
      )
  },

  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
]

export const routes: Routes = [...route]
