import { Component, signal } from '@angular/core'
import { CommonModule, Location } from '@angular/common'
import { trigger, state, style, animate, transition } from '@angular/animations'
import { Router } from '@angular/router'
import { AuthService } from '@/app/core/requests/auth.service'
import { map } from 'rxjs'

import { MenuCodes } from '@/enums/menu'

import type { OnInit } from '@angular/core'
import type { FormattedMenuItem } from '@/types/client/auth-client.type'

@Component({
  selector: 'app-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  animations: [
    trigger('collapseAnimation', [
      state('collapsed', style({ height: '0', overflow: 'hidden', opacity: 0 })),
      state('expanded', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')])
    ])
  ],
  imports: [CommonModule]
})
export class SidebarComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {}

  readonly menus = signal<FormattedMenuItem[]>([])

  openMenuCodesForAdmin: MenuCodes[] = [MenuCodes.DASHBOARD, MenuCodes.MAP_VIEW_ALL, MenuCodes.MAP_VIEW_SHARED] // 대쉬보드, 맵뷰(전체), 맵뷰(공유)

  toggleMenu(menu: FormattedMenuItem) {
    menu.isExpanded = !menu.isExpanded
  }

  navigateMenu(menu: FormattedMenuItem) {
    if (menu.uri) {
      if (menu.uri.startsWith('parkinglot')) {
        if (menu.uri.split('/')[1] === 'map') {
          window.location.href = `${window.location.origin}/map`
          return
        }
        if (menu.uri.split('/')[1] === 'partner-map') {
          window.location.href = `${window.location.origin}/sharedparkinglot-map`
          return
        }
      }
      this.router.navigate([menu.uri])
      return
    }
  }

  ngOnInit() {
    if (this.location.path() === '/login' || this.location.path() === '/') return
    this.authService
      .getMenuList()
      .pipe(
        map((response) => {
          const { result } = response
          const newMenuList: FormattedMenuItem[] = []

          const getParentMenu = (parentMenuCode: number) => {
            return result.find((menu) => menu.menuCode === parentMenuCode) as FormattedMenuItem
          }

          result.forEach((menu) => {
            const parentMenu = getParentMenu(menu.parentMenuCode)

            if (parentMenu) {
              parentMenu.subMenu = [...(parentMenu.subMenu || []), menu]
              parentMenu.isExpanded = false
            } else {
              newMenuList.push(menu)
            }
          })

          return newMenuList
        })
      )
      .subscribe({
        next: (formattedData) => {
          this.menus.set(formattedData)
        }
      })
  }
}
