import { Component } from '@angular/core'
import { RouterOutlet, Router, NavigationEnd } from '@angular/router'
import { CommonModule } from '@angular/common'
import { NgxLoadingBar } from '@ngx-loading-bar/core'
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs'

import { FooterComponent } from '@/app/shared/footer/footer.component'
import { HeaderComponent } from '@/app/shared/header/header.component'
import { SidebarComponent } from '@/app/shared/sidebar/sidebar.component'
import { SpinnerComponent } from '@/app/shared/spinner/spinner.component'

import type { OnInit } from '@angular/core'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    SpinnerComponent,
    NgxLoadingBar
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  private isShowingHeaderAndSidebarSubject = new BehaviorSubject(false)
  isShowingHeaderAndSidebar$ = this.isShowingHeaderAndSidebarSubject.asObservable()

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.url),
        distinctUntilChanged(),
        map((url) => !['/login'].includes(url))
      )
      .subscribe((isShow) => {
        this.isShowingHeaderAndSidebarSubject.next(isShow)
      })
  }
}
